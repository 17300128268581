import React, { useEffect } from "react"
import Layout from "../components/layout"

import VariationsSection from "../components/index-page/variations-section"
import AudienceSection from "../components/index-page/audience-section"
import BiddingSection from "../components/index-page/bidding-section"

import RequestDemoSection from "../components/request-demo-section"

import SEO from "../components/seo"

import "../styles/index.scss"
import HeroSection from "../components/index-page/hero-section"
import CountUp from "react-countup"
import loadable from "@loadable/component"
const AgenciesMarquee = loadable(
  () => import("../components/agencies-marquee"),
  {
    ssr: false,
  }
)

const IndexPage = props => {
  useEffect(() => {
    if (window && window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "DC-13891859/invmedia/ytpix0+standard",
      })
    }
  })
  return (
    <Layout>
      <SEO
        title="AI for Your DSP & Native Channels"
        description="inPowered AI is an AI platform that integrates directly into DSPs and Native Advertising channels to maximize campaign performance."
      />
      <HeroSection></HeroSection>

      <section>
        <AgenciesMarquee></AgenciesMarquee>
      </section>

      <div className="home-articles has-background-light py-6">
        <section className="container px-4 ">
          <div className="mb-6">
            <h2 className="title is-1 has-text-weight-bold has-text-dark has-text-centered mb-4">
              AI Delivers 70% Uplift On Average For Any KPI
            </h2>
            <p className="subtitle has-text-centered is-size-5 my-2">
              Whether your goal is to drive traffic, engagement, leads,
              conversions, or ROAS inPowered AI deploys
              <br /> the right algo to maximize your performance.
            </p>
          </div>

          <article className="home-article">
            <h3 className="title is-2 my-0 has-text-weight-bold has-text-dark">
              AI Generates Creatives That Drive Results
            </h3>
            <p className="description is-size-5 has-line-height-4">
              inPowered AI is trained on proprietary post-click engagement data
              to generate the ideal Native creative variations predicted to
              maximize your KPI. All you need to do is select your desired
              outcomes. Your team maintains creative control by reviewing every
              variant and approving them before going live.
            </p>
            <div className="visuals" style={{ overflow: "hidden" }}>
              <VariationsSection></VariationsSection>
            </div>
          </article>
          <article className="home-article reverse">
            <h3 className="title is-2 my-0 has-text-weight-bold has-text-dark">
              AI Custom Bidding Models Maximize Media Efficiency
            </h3>
            <p className="description is-size-5 has-line-height-4">
              inPowered AI deploys custom bidding models trained on your
              impression-level data and inPowered AI’s proprietary engagement
              data in your DSP for each campaign. You select the outcomes that
              matter most to you - engagement, leads, conversions, or ROAS and
              the right model is generated and deployed to maximize your KPI.
            </p>
            <div className="visuals">
              <BiddingSection></BiddingSection>
            </div>
          </article>
        </section>
      </div>

      <div className="container px-4">
        <article className="home-article my-6">
          <h3 className="title is-2 my-0 has-text-weight-bold has-text-dark">
            AI Is The New Audience
          </h3>
          <p className="description is-size-5 has-line-height-4">
            inPowered AI is trained on impression-level data, capable of
            predicting which impression will result in your desired outcome.
            This eliminates the need to rely exclusively on targeting specific
            audiences to reach your goals. As the third-party cookie is phased
            out, you can be confident that our platform will continue to deliver
            performance - now and in the future.
          </p>
          <div className="visuals">
            <AudienceSection></AudienceSection>
          </div>
        </article>
      </div>

      <section className="has-background-dark py-6">
        <div className="container px-4">
          <div className="columns">
            <div className="column is-hidden-desktop-only is-hidden-touch"></div>
            <div className="column has-text-centered">
              <h2 className="title is-1 mb-3 has-text-link">
                $
                <CountUp enableScrollSpy scrollSpyOnce end={100} duration={2} />
                m+
              </h2>
              <p className="is-size-5 has-text-light has-text-white">
                Ad Spend
              </p>
            </div>
            <div className="column has-text-centered">
              <h2 className="title is-1 mb-3 has-text-link">
                <CountUp enableScrollSpy scrollSpyOnce end={70} duration={2} />%
              </h2>
              <p className="is-size-5 has-text-light has-text-white">
                Average KPI Uplift
              </p>
            </div>
            <div className="column has-text-centered">
              <h2 className="title is-1 mb-3 has-text-link">
                <CountUp
                  enableScrollSpy
                  scrollSpyOnce
                  end={5000}
                  duration={2}
                />
                +
              </h2>
              <p className="is-size-5 has-text-light has-text-white">
                Campaigns
              </p>
            </div>
            <div className="column is-hidden-desktop-only is-hidden-touch"></div>
          </div>
        </div>
      </section>

      <section className="py-6 has-background-white" id="request-demo">
        <RequestDemoSection></RequestDemoSection>
      </section>
    </Layout>
  )
}

export default IndexPage
